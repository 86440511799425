
import {
	defineComponent,
	SetupContext,
	ref,
	onBeforeMount,
	PropType,
} from 'vue'
import CodeParameters from '@/components/worklist/requirements/parameters/code-parameters.vue'
import StateParameters from '@/components/worklist/requirements/parameters/state-parameters.vue'
import PosParameters from '@/components/worklist/requirements/parameters/pos-parameters.vue'
import DiagnosisParameters from '@/components/worklist/requirements/parameters/diagnosis-parameters.vue'
import PriceParameters from '@/components/worklist/requirements/parameters/price-parameters.vue'
import QuantityParameters from '@/components/worklist/requirements/parameters/quantity-parameters.vue'
import PlanParameters from '@/components/worklist/requirements/parameters/plan-parameters.vue'
import { IRequirementField } from '@server/api/models/fe/requirements'
import {
	FieldOperation,
	FieldOperationName,
	FieldType,
	FieldTypeName,
} from '@server/api/models/fe/shared'

export default defineComponent({
	name: 'requirement-field',
	components: {
		[CodeParameters.name]: CodeParameters,
		[StateParameters.name]: StateParameters,
		[PosParameters.name]: PosParameters,
		[DiagnosisParameters.name]: DiagnosisParameters,
		[PriceParameters.name]: PriceParameters,
		[PlanParameters.name]: PlanParameters,
		QuantityParameters,
	},
	props: {
		field: { type: Object as PropType<IRequirementField>, required: true },
		canEditDetail: Boolean,
		payerId: String,
	},
	setup(props, context: SetupContext) {
		const fieldTypeOptions = ref([
			FieldType.PROCEDURE_CODE,
			FieldType.PATIENT_STATE,
			FieldType.PROCEDURE_PLACE_OF_SERVICE,
			FieldType.DIAGNOSIS,
			FieldType.PROCEDURE_PRICE,
			FieldType.PROCEDURE_QUANTITY,
			FieldType.PLAN_NAME,
		])
		const fieldTypeName = FieldTypeName
		const fieldType = FieldType
		const fieldOperations = Object.values(FieldOperation)
		const fieldOperationName = FieldOperationName
		const selectedOperation = ref<FieldOperation | null>(null)
		const selectedType = ref(props.field.type ?? null)
		const isEditingField = ref(false)
		const isEditingOperation = ref(false)

		const updateType = () => {
			props.field.type = selectedType.value
			updateField()
		}

		const updateField = () => {
			context.emit('updateField')
			isEditingField.value = false
			isEditingOperation.value = false
		}

		const deleteField = () => {
			context.emit('deleteField')
		}

		const onOperationSelect = () => {
			if (
				(props.field.type === FieldType.PROCEDURE_PRICE ||
					props.field.type === FieldType.PROCEDURE_QUANTITY) &&
				selectedOperation.value
			) {
				props.field.parameters.operation = selectedOperation.value
				if (selectedOperation.value !== FieldOperation.BETWEEN) {
					props.field.parameters.high = null
				}
				updateField()
			}
		}

		onBeforeMount(() => {
			if (
				props.field.type === FieldType.PROCEDURE_PRICE ||
				props.field.type === FieldType.PROCEDURE_QUANTITY
			) {
				selectedOperation.value = props.field.parameters.operation
			}
		})

		return {
			fieldType,
			fieldTypeName,
			fieldTypeOptions,
			isEditingField,
			selectedType,
			fieldOperations,
			fieldOperationName,
			selectedOperation,
			isEditingOperation,

			updateType,
			updateField,
			deleteField,
			onOperationSelect,
		}
	},
})
