
import { defineComponent, onMounted, ref, SetupContext, PropType } from 'vue'
import { useStore } from '@/utils/composables'

import {
	IPlanItem,
	IPlanNameParameters,
} from '@server/api/models/fe/requirements'

import { fetchPayerPlanAssociations } from '@/service/planAssociation.http'
import { IPublishedPlanAssociation } from '@server/api/models/fe/planAssociation'

export default defineComponent({
	name: 'plan-parameters',
	components: {},
	props: {
		parameters: {
			type: Object as PropType<IPlanNameParameters>,
			required: true,
		},
		canEditDetail: Boolean,
		payerId: { type: String, required: true },
	},
	setup(props, context: SetupContext) {
		const store = useStore()

		const plans = ref<IPlanItem[]>([])
		const options = ref<IPlanItem[]>([])
		const selectedPlans = ref(props.parameters.plans ?? [])
		const payerId = props.payerId

		onMounted(() => {
			fetchPayerPlanAssociations(payerId).then((res: any) => {
				plans.value = res
					.map((payerPlan: IPublishedPlanAssociation) => {
						return {
							name: payerPlan.name,
							id: payerPlan.id,
						}
					})
					.sort((a: { name: string }, b: { name: string }) =>
						a.name.localeCompare(b.name)
					)
				options.value = plans.value

				store.dispatch('worklist/setPlans', plans.value)

				setSelectedPlans()
			})
		})

		const setSelectedPlans = () => {
			selectedPlans.value = selectedPlans.value.map(selectedPlan => {
				const plan = plans.value.filter(x => x.id === selectedPlan.id)
				return plan[0] || selectedPlan
			})
		}

		const updateParameters = () => {
			props.parameters.plans = selectedPlans.value
			context.emit('updateField')
		}

		return {
			options,
			selectedPlans,

			updateParameters,
		}
	},
})
