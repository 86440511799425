
import { computed, defineComponent, ref, SetupContext, PropType } from 'vue'
import PhoneOutcome from '@/components/worklist/requirements/outcome/phone-outcome.vue'
import FaxOutcome from '@/components/worklist/requirements/outcome/fax-outcome.vue'
import PortalOutcome from '@/components/worklist/requirements/outcome/portal-outcome.vue'
import GuidelineOutcome from '@/components/worklist/requirements/outcome/guideline-outcome.vue'
import {
	ContactMethodType,
	IGuideline,
	IItemOutcome,
} from '@server/api/models/fe/shared'
import { IWorklistItem } from '@server/api/models/fe/worklist'
import { IRequirementField } from '@server/api/models/fe/requirements'

export default defineComponent({
	name: 'contact-methods',
	components: {
		[PhoneOutcome.name]: PhoneOutcome,
		[FaxOutcome.name]: FaxOutcome,
		[PortalOutcome.name]: PortalOutcome,
		[GuidelineOutcome.name]: GuidelineOutcome,
	},
	props: {
		worklistItem: { type: Object as PropType<IWorklistItem>, required: true },
		outcome: { type: Object as PropType<IItemOutcome>, required: true },
		fields: { type: Array as PropType<IRequirementField[]>, default: () => [] },
		canEditDetail: Boolean,
	},
	setup(props, context: SetupContext) {
		const selectedMethod = ref<ContactMethodType | null>(
			props.outcome.preferredMethod
		)
		const canEditContactMethods = computed(() => {
			return props.fields.some(field => field.type)
		})

		const selectPreferredMethod = (method: ContactMethodType) => {
			selectedMethod.value = method
			props.outcome.preferredMethod = selectedMethod.value
			context.emit('updateRequirement')
		}

		const onMethodChange = (unsetPreferredMethod: boolean) => {
			if (unsetPreferredMethod) {
				props.outcome.preferredMethod = null
			}
			context.emit('updateRequirement')
		}

		const onGuidelineChange = (guidelines: IGuideline[]) => {
			props.outcome.assets = guidelines
			context.emit('updateRequirement')
		}

		return {
			selectedMethod,
			selectPreferredMethod,
			canEditContactMethods,
			onMethodChange,
			onGuidelineChange,
		}
	},
})
