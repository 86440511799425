
import { defineComponent, ref, SetupContext, PropType } from 'vue'
import { AssetType } from '@myndshft/types-mc-api'
import { fetchAssets } from '@/service/worklist.http'
import { IRequirementOutcome } from '@server/api/models/fe/requirements'
import { IGuideline } from '@server/api/models/fe/shared'
import { IContactFaxMethod } from '@server/api/models/fe/worklist'

interface IContactFax extends IContactFaxMethod {
	selected?: boolean
}

export default defineComponent({
	name: 'guideline-outcome',
	components: {},
	props: {
		payerId: { type: String, required: true },
		outcome: { type: Object as PropType<IRequirementOutcome>, required: true },
		canEdit: Boolean,
	},
	setup(props, context: SetupContext) {
		const selectedGuidelines = ref<IGuideline[]>(
			[...props.outcome.assets] ?? []
		)
		const guidelines = ref<IGuideline[]>([])
		const isEditing = ref(false)
		const isLoading = ref(false)
		const fetchItems = () => {
			if (!guidelines.value.length) {
				isLoading.value = true
				// Get phone selections if it does not exist
				fetchAssets(props.payerId, { type: AssetType.GUIDELINE }).then(
					response => {
						guidelines.value = response
						isLoading.value = false
					}
				)
			}
		}

		const isSelected = (faxOption: IContactFax) => {
			return (
				selectedGuidelines.value.findIndex(
					guideline => guideline.id === faxOption.id
				) > -1
			)
		}

		const onInput = () => {
			context.emit('onGuidelineChange', selectedGuidelines.value)
		}

		return {
			selectedGuidelines,
			guidelines,
			isEditing,
			isLoading,
			fetchItems,
			isSelected,

			onInput,
		}
	},
})
