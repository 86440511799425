
import { fetchItemPortals } from '@/service/worklist.http'
import { IResponsePortalMethod } from '@myndshft/types-mc-api'
import { computed, defineComponent, ref, SetupContext, PropType } from 'vue'
import { ContactMethodType } from '@server/api/models/fe/shared'
import {
	IContactMethods,
	IContactPortalMethod,
} from '@server/api/models/fe/worklist'

interface IContactPortal extends IContactPortalMethod {
	selected?: boolean
}

export default defineComponent({
	name: 'portal-outcome',
	components: {},
	props: {
		payerId: { type: String, required: true },
		planId: { type: String, required: true },
		selectedMethod: String as PropType<ContactMethodType>,
		contactMethods: {
			type: Object as PropType<IContactMethods>,
			required: true,
		},
		canEdit: Boolean,
	},
	setup(props, context: SetupContext) {
		const selectedPortal = ref<IResponsePortalMethod | null>(
			props.contactMethods.portal
		)
		const portals = ref<IContactPortal[]>([])
		const isEditing = ref(false)
		const isLoading = ref(false)
		const isPreferred = computed(() => {
			return props.selectedMethod === ContactMethodType.PORTAL
		})
		const shouldDisplayCheck = computed(() => {
			return (props.canEdit && selectedPortal.value) || isPreferred.value
		})
		const fetchItems = () => {
			if (!portals.value.length) {
				isLoading.value = true
				// Get phone selections if it does not exist
				fetchItemPortals(props.payerId, props.planId).then(response => {
					portals.value = response
					isLoading.value = false
				})
			}
		}

		const isSelected = (phone: IContactPortal) => {
			return selectedPortal.value?.id === phone.id
		}

		const onInput = () => {
			props.contactMethods.portal = selectedPortal.value
			context.emit('onMethodChange', isPreferred.value && !selectedPortal.value)
		}

		const selectMethod = () => {
			if (props.canEdit) {
				context.emit('selectMethod', ContactMethodType.PORTAL)
			}
		}

		return {
			selectedPortal,
			portals,
			isEditing,
			isLoading,
			fetchItems,
			isSelected,
			isPreferred,
			shouldDisplayCheck,

			onInput,
			selectMethod,
		}
	},
})
