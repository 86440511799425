
import {
	defineComponent,
	SetupContext,
	ref,
	reactive,
	computed,
	PropType,
	watchEffect,
} from 'vue'
import { fetchItemPhones } from '@/service/worklist.http'
import { IResponsePhoneMethod } from '@myndshft/types-mc-api'
import {
	IContactPhoneMethod,
	IWorklistItem,
	WorklistItemStatus,
} from '@server/api/models/fe/worklist'
import { ITableProp } from '@server/api/models/fe/shared'

interface IContactPhone extends IContactPhoneMethod {
	selected?: boolean
}

export default defineComponent({
	name: 'worklist-phone',
	props: {
		item: {
			type: Object as PropType<IWorklistItem & ITableProp>,
			required: true,
		},
		canEdit: {
			type: Boolean,
			default: false,
		},
		isBulkEditing: Boolean,
		haveSamePayerPlan: Boolean,
		index: Number,
	},
	setup(props, context: SetupContext) {
		const selectedContact = ref<IResponsePhoneMethod | null>(null)
		const phones = reactive({
			list: [],
		})
		const show = ref(false)
		const isLoading = ref(false)
		const isPreferred = ref(false)

		const canSelect = computed(() => {
			return (
				props.canEdit &&
				// while in bulk edit state, only selected items can be edited
				(props.isBulkEditing
					? props.item.isSelected && props.haveSamePayerPlan
					: true) &&
				props.item.status !== WorklistItemStatus.PENDING_PUBLISH
			)
		})
		const disabledMessage = computed<string | null>(() => {
			if (props.isBulkEditing) {
				if (!props.item.isSelected) {
					return 'Item is not selected for bulk editing'
				} else if (!props.haveSamePayerPlan) {
					return 'The selected items do not share the same Payer/Plan'
				}
			}
			return null
		})

		const onInput = () => {
			context.emit('onItemUpdate', updateOperation)
		}

		const updateOperation = (): { itemCopy: IWorklistItem } => {
			const itemCopy = JSON.parse(JSON.stringify(props.item))
			itemCopy.outcome.contactMethods.phone = selectedContact.value
			itemCopy.isSaving.phone = true
			return { itemCopy }
		}

		const onPreferredToggle = () => {
			if (canSelect.value) {
				context.emit('onItemUpdate', updateOperationPreferredMethod)
			}
		}

		const updateOperationPreferredMethod = (): { itemCopy: IWorklistItem } => {
			const itemCopy = JSON.parse(JSON.stringify(props.item))
			itemCopy.outcome.preferredMethod = isPreferred.value ? null : 'PHONE'
			itemCopy.isSaving.preferredMethod = true
			return { itemCopy }
		}

		const fetchItems = () => {
			isLoading.value = true
			// Get phone selections if it does not exist
			fetchItemPhones(props.item.payer.id, props.item.plan.id).then(
				response => {
					phones.list = response
					isLoading.value = false
				}
			)
		}

		const isSelected = (phone: IContactPhone) => {
			return selectedContact.value?.id === phone.id
		}

		watchEffect(() => {
			selectedContact.value = props.item.outcome.contactMethods.phone
		})

		watchEffect(() => {
			isPreferred.value = props.item.outcome.preferredMethod === 'PHONE'
		})

		return {
			selectedContact,
			phones,
			show,
			isLoading,
			isPreferred,
			canSelect,
			disabledMessage,

			fetchItems,
			isSelected,
			onInput,
			onPreferredToggle,
		}
	},
})
