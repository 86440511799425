
import { computed, defineComponent, ref, SetupContext, PropType } from 'vue'

import { fetchItemFaxes } from '@/service/worklist.http'
import { IResponseFaxMethod } from '@myndshft/types-mc-api'
import { ContactMethodType } from '@server/api/models/fe/shared'
import {
	IContactFaxMethod,
	IContactMethods,
} from '@server/api/models/fe/worklist'

interface IContactFax extends IContactFaxMethod {
	selected?: boolean
}

export default defineComponent({
	name: 'fax-outcome',
	components: {},
	props: {
		payerId: { type: String, required: true },
		planId: { type: String, required: true },
		selectedMethod: String as PropType<ContactMethodType>,
		contactMethods: {
			type: Object as PropType<IContactMethods>,
			required: true,
		},
		canEdit: Boolean,
	},
	setup(props, context: SetupContext) {
		const selectedFaxes = ref<IResponseFaxMethod[]>(
			[...(props.contactMethods.faxes || '')] ?? []
		)
		const faxes = ref<IContactFax[]>([])
		const isEditing = ref(false)
		const isLoading = ref(false)
		const isPreferred = computed(() => {
			return props.selectedMethod === ContactMethodType.FAX
		})
		const shouldDisplayCheck = computed(() => {
			return (props.canEdit && selectedFaxes.value.length) || isPreferred.value
		})
		const fetchItems = () => {
			if (!faxes.value.length) {
				isLoading.value = true
				// Get phone selections if it does not exist
				fetchItemFaxes(props.payerId, props.planId).then(response => {
					faxes.value = response
					isLoading.value = false
				})
			}
		}

		const isSelected = (faxOption: IContactFax) => {
			return selectedFaxes.value.findIndex(fax => fax.id === faxOption.id) > -1
		}

		const onInput = () => {
			props.contactMethods.faxes = selectedFaxes.value
			context.emit(
				'onMethodChange',
				isPreferred.value && !selectedFaxes.value.length
			)
		}

		const selectMethod = () => {
			if (props.canEdit) {
				context.emit('selectMethod', ContactMethodType.FAX)
			}
		}

		return {
			selectedFaxes,
			faxes,
			isEditing,
			isLoading,
			fetchItems,
			isSelected,
			isPreferred,
			shouldDisplayCheck,

			onInput,
			selectMethod,
		}
	},
})
