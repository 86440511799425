
import {
	defineComponent,
	SetupContext,
	ref,
	computed,
	watch,
	PropType,
} from 'vue'
import {
	AuthRequirement,
	AuthRequirementName,
} from '@server/api/models/fe/shared'
import {
	IWorklistItem,
	WorklistItemStatus,
} from '@server/api/models/fe/worklist'
import { ITableProp } from '@server/api/models/fe/shared'

export default defineComponent({
	name: 'worklist-pa-determination',
	props: {
		item: {
			type: Object as PropType<IWorklistItem & ITableProp>,
			required: true,
		},
		canEdit: {
			type: Boolean,
			default: false,
		},
		isBulkEditing: Boolean,
		index: Number,
	},
	setup(props, context: SetupContext) {
		const selectedDetermination = ref<AuthRequirement | null>(null)
		const authRequirements = ref(Object.values(AuthRequirement))
		const show = ref(false)
		const authRequirementName = AuthRequirementName

		const canSelect = computed(() => {
			return (
				props.canEdit &&
				// while in bulk edit state, only selected items can be edited
				(props.isBulkEditing ? props.item.isSelected : true) &&
				props.item.status !== WorklistItemStatus.PENDING_PUBLISH
			)
		})

		watch(
			() => props.item.outcome.authRequirement,
			authRequirement => {
				selectedDetermination.value = authRequirement
			}
		)

		const disabledMessage = computed<string | null>(() => {
			if (props.isBulkEditing) {
				if (!props.item.isSelected) {
					return 'Item is not selected for bulk editing'
				}
			}
			return null
		})

		const onInput = () => {
			context.emit('onItemUpdate', updateOperation)
		}

		const updateOperation = (): { itemCopy: IWorklistItem } => {
			const itemCopy = JSON.parse(JSON.stringify(props.item))
			itemCopy.outcome.authRequirement = selectedDetermination.value
			itemCopy.isSaving.authRequirement = true
			return { itemCopy }
		}

		return {
			selectedDetermination,
			authRequirements,
			authRequirementName,
			show,
			onInput,
			canSelect,
			disabledMessage,
		}
	},
})
