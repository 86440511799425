
import { computed, defineComponent, SetupContext } from 'vue'
import { useStore, usePermission } from '@/utils/composables'

interface Props {
	isBulkEditing: boolean
	isPasting: boolean
	canPasteRequirements: boolean
}

export default defineComponent({
	name: 'paste-requirements-button',
	props: {
		isBulkEditing: {
			type: Boolean,
			default: false,
		},
		isPasting: {
			type: Boolean,
			default: false,
		},
		canPasteRequirements: {
			type: Boolean,
			default: false,
		},
	},
	setup(props: Props, context: SetupContext) {
		const store = useStore()
		const permission = usePermission()

		const onPasteRequirements = () => {
			context.emit('pasteRequirements')
		}

		const pasteSetTooltip = computed(() => {
			if (!store.state.copyPaste.type) {
				return `Nothing on clipboard`
			}
			if (!permission.$canEditWorklist()) {
				return `You don't have permission`
			}
			if (!props.isBulkEditing) {
				return `Can't Paste: No items selected`
			}
			if (!props.canPasteRequirements) {
				return `Can't Paste: All Payers and Plans must match clipboard`
			}
			return 'This will replace all existing requirements in selected items'
		})
		return {
			onPasteRequirements,
			pasteSetTooltip,
		}
	},
})
