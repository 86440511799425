export function useFormatting() {
	function trimLabel(label: string, maxLength: number) {
		if (label.length > maxLength) {
			return label.substring(0, maxLength) + '...'
		}
		return label
	}

	function toPercent(value: number | string): string {
		return (Number(value) * 100).toLocaleString('en-us') + '%'
	}

	function toCurrency(value: number | string): string {
		return Number(value).toLocaleString('en-US', {
			currency: 'USD',
			style: 'currency',
			maximumFractionDigits: 4,
		})
	}

	function toPhoneNumber(value: string): string {
		// Insert dashes
		if (value.length === 3 || value.length === 7) {
			value += '-'
		}

		return value
	}

	function toZipCode(value: string): string {
		// Insert dashes
		if (value.length === 6 && !value.includes('-')) {
			const valueArray = value.split('')
			valueArray.splice(5, 0, '-')
			value = valueArray.join('')
		}

		return value
	}

	return { trimLabel, toPercent, toCurrency, toPhoneNumber, toZipCode }
}
