import { render, staticRenderFns } from "./modifiedby-tooltip.vue?vue&type=template&id=8e4550ca&scoped=true"
import script from "./modifiedby-tooltip.vue?vue&type=script&lang=ts"
export * from "./modifiedby-tooltip.vue?vue&type=script&lang=ts"
import style0 from "./modifiedby-tooltip.vue?vue&type=style&index=0&id=8e4550ca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e4550ca",
  null
  
)

export default component.exports