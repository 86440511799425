export const modifiers = [
	'22',
	'23',
	'24',
	'25',
	'26',
	'27',
	'32',
	'33',
	'47',
	'50',
	'51',
	'52',
	'53',
	'54',
	'55',
	'56',
	'57',
	'58',
	'59',
	'62',
	'63',
	'66',
	'73',
	'74',
	'76',
	'77',
	'78',
	'79',
	'80',
	'81',
	'82',
	'90',
	'91',
	'92',
	'95',
	'96',
	'97',
	'99',
	'A1',
	'A2',
	'A3',
	'A4',
	'A5',
	'A6',
	'A7',
	'A8',
	'A9',
	'AA',
	'AD',
	'AE',
	'AF',
	'AG',
	'AH',
	'AI',
	'AJ',
	'AK',
	'AM',
	'AO',
	'AP',
	'AQ',
	'AR',
	'AS',
	'AT',
	'AU',
	'AV',
	'AW',
	'AX',
	'AY',
	'AZ',
	'BA',
	'BL',
	'BO',
	'BP',
	'BR',
	'BU',
	'CA',
	'CB',
	'CC',
	'CD',
	'CE',
	'CF',
	'CG',
	'CH',
	'CI',
	'CJ',
	'CK',
	'CL',
	'CM',
	'CN',
	'CO',
	'CQ',
	'CR',
	'CS',
	'CT',
	'DA',
	'E1',
	'E2',
	'E3',
	'E4',
	'EA',
	'EB',
	'EC',
	'ED',
	'EE',
	'EJ',
	'EM',
	'EP',
	'ER',
	'ET',
	'EX',
	'EY',
	'F1',
	'F2',
	'F3',
	'F4',
	'F5',
	'F6',
	'F7',
	'F8',
	'F9',
	'FA',
	'FB',
	'FC',
	'FP',
	'FX',
	'FY',
	'G0',
	'G1',
	'G2',
	'G3',
	'G4',
	'G5',
	'G6',
	'G7',
	'G8',
	'G9',
	'GA',
	'GB',
	'GC',
	'GE',
	'GF',
	'GG',
	'GH',
	'GJ',
	'GK',
	'GL',
	'GM',
	'GN',
	'GO',
	'GP',
	'GQ',
	'GR',
	'GS',
	'GT',
	'GU',
	'GV',
	'GW',
	'GX',
	'GY',
	'GZ',
	'H9',
	'HA',
	'HB',
	'HC',
	'HD',
	'HE',
	'HF',
	'HG',
	'HH',
	'HI',
	'HJ',
	'HK',
	'HL',
	'HM',
	'HN',
	'HO',
	'HP',
	'HQ',
	'HR',
	'HS',
	'HT',
	'HU',
	'HV',
	'HW',
	'HX',
	'HY',
	'HZ',
	'J1',
	'J2',
	'J3',
	'J4',
	'JA',
	'JB',
	'JC',
	'JD',
	'JE',
	'JG',
	'JW',
	'K0',
	'K1',
	'K2',
	'K3',
	'K4',
	'KA',
	'KB',
	'KC',
	'KD',
	'KE',
	'KF',
	'KG',
	'KH',
	'KI',
	'KJ',
	'KK',
	'KL',
	'KM',
	'KN',
	'KO',
	'KP',
	'KQ',
	'KR',
	'KS',
	'KT',
	'KU',
	'KV',
	'KW',
	'KX',
	'KY',
	'KZ',
	'LC',
	'LD',
	'LL',
	'LM',
	'LR',
	'LS',
	'LT',
	'M2',
	'MA',
	'MB',
	'MC',
	'MD',
	'ME',
	'MF',
	'MG',
	'MH',
	'MS',
	'NB',
	'NR',
	'NU',
	'P1',
	'P2',
	'P3',
	'P4',
	'P5',
	'P6',
	'PA',
	'PB',
	'PC',
	'PD',
	'PI',
	'PL',
	'PM',
	'PN',
	'PO',
	'PS',
	'PT',
	'Q0',
	'Q1',
	'Q2',
	'Q3',
	'Q4',
	'Q5',
	'Q6',
	'Q7',
	'Q8',
	'Q9',
	'QA',
	'QB',
	'QC',
	'QD',
	'QE',
	'QF',
	'QG',
	'QH',
	'QJ',
	'QK',
	'QL',
	'QM',
	'QN',
	'QP',
	'QQ',
	'QR',
	'QS',
	'QT',
	'QW',
	'QX',
	'QY',
	'QZ',
	'RA',
	'RB',
	'RC',
	'RD',
	'RE',
	'RI',
	'RR',
	'RT',
	'SA',
	'SB',
	'SC',
	'SD',
	'SE',
	'SF',
	'SG',
	'SH',
	'SJ',
	'SK',
	'SL',
	'SM',
	'SN',
	'SQ',
	'SS',
	'ST',
	'SU',
	'SV',
	'SW',
	'SY',
	'T1',
	'T2',
	'T3',
	'T4',
	'T5',
	'T6',
	'T7',
	'T8',
	'T9',
	'TA',
	'TB',
	'TC',
	'TD',
	'TE',
	'TF',
	'TG',
	'TH',
	'TJ',
	'TK',
	'TL',
	'TM',
	'TN',
	'TP',
	'TQ',
	'TR',
	'TS',
	'TT',
	'TU',
	'TV',
	'TW',
	'U1',
	'U2',
	'U3',
	'U4',
	'U5',
	'U6',
	'U7',
	'U8',
	'U9',
	'UA',
	'UB',
	'UC',
	'UD',
	'UE',
	'UF',
	'UG',
	'UH',
	'UJ',
	'UK',
	'UN',
	'UP',
	'UQ',
	'UR',
	'US',
	'V1',
	'V2',
	'V3',
	'V4',
	'V5',
	'V6',
	'V7',
	'VM',
	'VP',
	'X1',
	'X2',
	'X3',
	'X4',
	'X5',
	'XE',
	'XP',
	'XS',
	'XU',
	'J5',
]
