
import { defineComponent, SetupContext } from 'vue'

export default defineComponent({
	name: 'default-requirement',
	props: {
		worklistItem: Object,
		requirementSet: Object,
		canEditDetail: {
			type: Boolean,
			default: false,
		},
	},

	setup(_, context: SetupContext) {
		const onInput = () => {
			context.emit('setDefault')
		}
		return {
			onInput,
		}
	},
})
