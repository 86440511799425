
import { defineComponent, SetupContext, computed, ref, PropType } from 'vue'
import {
	IWorklistItem,
	WorklistItemStatus,
} from '@server/api/models/fe/worklist'

interface IType {
	type: string
	cssClass: string
}

export default defineComponent({
	name: 'worklist-status',
	props: {
		item: {
			type: Object as PropType<IWorklistItem>,
			required: true,
		},
		canEditProgress: {
			type: Boolean,
			default: false,
		},
		canEditStatus: {
			type: Boolean,
			default: false,
		},
		haveSameStatus: {
			type: Boolean,
			default: false,
		},
		index: {
			type: Number,
			default: 0,
		},
	},
	setup(props, context: SetupContext) {
		const worklistItemStatus = WorklistItemStatus
		const updatedStatus = ref<WorklistItemStatus | null>(null)

		const itemStatus = computed(() => {
			const editable = canSetReviewStatus.value ? 'can-edit' : ''

			switch (props.item.status) {
				case WorklistItemStatus.IN_PROGRESS:
					return {
						type: 'P',
						cssClass: `in-progress ${editable}`,
					} as IType
				case WorklistItemStatus.IN_REVIEW:
					return { type: 'RV', cssClass: `in-review ${editable}` } as IType
				case WorklistItemStatus.PENDING_PUBLISH:
					return { type: 'PP', cssClass: `pending-publish` } as IType
				case WorklistItemStatus.FAILED_PUBLISH:
					return { type: 'RV', cssClass: `failed-publish ${editable}` } as IType
				default:
					return { type: '?', cssClass: '' } as IType
			}
		})

		// Can edit conditions:
		// 1. User is assigned to this worklist, and status is in progress
		// 2. User is a PA Lead
		const canEdit = computed(() => {
			if (
				props.canEditStatus ||
				(props.item.status === WorklistItemStatus.IN_PROGRESS &&
					props.canEditProgress)
			) {
				return true
			}
			return false
		})

		const canSetReviewStatus = computed(() => {
			return !!(
				props.item.summary.meetsRequirements &&
				canEdit.value &&
				props.haveSameStatus
			)
		})

		const updateOperation = (): { itemCopy: IWorklistItem } => {
			const itemCopy = JSON.parse(JSON.stringify(props.item))
			itemCopy.status = updatedStatus.value
			itemCopy.isSaving.status = true
			return { itemCopy }
		}

		const onStatus = () => {
			if (
				props.haveSameStatus &&
				canSetReviewStatus.value &&
				props.item.status !== WorklistItemStatus.PENDING_PUBLISH
			) {
				updatedStatus.value =
					props.item.status === WorklistItemStatus.IN_PROGRESS
						? WorklistItemStatus.IN_REVIEW
						: WorklistItemStatus.IN_PROGRESS

				context.emit('onItemUpdate', updateOperation)
			}
		}

		return {
			itemStatus,
			worklistItemStatus,
			onStatus,
			canSetReviewStatus,
		}
	},
})
