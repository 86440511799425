import {
	IDiagnosisParameters,
	IProcedureWithOperationParameters,
	IRequirement,
	IRequirementSet,
} from '@server/api/models/fe/requirements'

import {
	FieldOperation,
	FieldType,
	IPlaceOfService,
} from '@server/api/models/fe/shared'

import store from '@/store'
import { getStateNamesByCodes } from '@/utils/helpers'
import { useFormatting } from '@/dependencies/formatting'

export class RequirementStatement {
	private requirementSet: IRequirementSet
	private tempRequirementSet: IRequirementSet
	private defaultDescription = 'New Requirement Set'

	constructor(requirementSet: IRequirementSet) {
		// Store the original requirement set
		this.requirementSet = requirementSet
		this.tempRequirementSet = JSON.parse(JSON.stringify(requirementSet))
	}

	updateRequirementStatements() {
		const requirements = this.tempRequirementSet.requirements

		if (requirements) {
			requirements.forEach(requirement => {
				const fields = requirement.fields
				if (fields) {
					requirement.statement = ''
					requirement = this.parseFields(requirement)
				}
			})

			this.requirementSet.requirements.map(requirement => {
				const tempRequirement = this.tempRequirementSet.requirements.find(
					req => {
						return req.id === requirement.id
					}
				)

				if (tempRequirement) {
					// Update requirement set statement
					requirement.statement = tempRequirement.statement
				}
			})
		}

		return this.requirementSet
	}

	// Provide a consistent display sort order for the requirement fields
	private sortFieldsByType(requirement: IRequirement) {
		const typeOrder: any = {
			PATIENT_STATE: 1,
			PROCEDURE_PLACE_OF_SERVICE: 2,
			DIAGNOSIS: 3,
			PROCEDURE_PRICE: 4,
			PROCEDURE_QUANTITY: 5,
			PROCEDURE_CODE: 6,
			PLAN_NAME: 7,
		}

		return requirement.fields.sort((field1, field2) => {
			return typeOrder[field1.type] - typeOrder[field2.type]
		})
	}

	private parseFields(requirement: IRequirement) {
		if (requirement.fields) {
			// Sort to keep the type order consistent for display
			const fields = this.sortFieldsByType(requirement)

			// Build the description for each field
			fields.forEach(field => {
				switch (field.type) {
					case FieldType.PATIENT_STATE:
						this.getPatientStateDescription(field.parameters.state, requirement)
						break
					case FieldType.PROCEDURE_PLACE_OF_SERVICE:
						this.getPlaceOfServiceDescription(
							field.parameters.codes,
							requirement
						)
						break
					case FieldType.DIAGNOSIS:
						this.getDiagnosisDescription(field.parameters, requirement)
						break
					case FieldType.PROCEDURE_PRICE:
						this.getPriceDescription(field.parameters, requirement)
						break
					case FieldType.PROCEDURE_QUANTITY:
						this.getQuantityDescription(field.parameters, requirement)
						break
					case FieldType.PROCEDURE_CODE:
						this.getProcedureCodeMofifierDescription(
							field.parameters.modifiers,
							requirement
						)
						break
					case FieldType.PLAN_NAME:
						this.getPlanNameDescription(field.parameters.plans, requirement)
						break
				}
			})
		}

		// Set the default description if none was provided
		if (!requirement.statement) {
			requirement.statement = this.defaultDescription
		}

		return requirement
	}

	private getPatientStateDescription(
		patientStates: string[] = [],
		requirement: IRequirement
	) {
		const states = patientStates.sort()
		const stateMap = getStateNamesByCodes(states)

		if (stateMap.length) {
			const deliminatedStates = this.deliminateDescriptionValues(stateMap)
			requirement.statement += `State is ${deliminatedStates}. `
		}
	}

	private getPlaceOfServiceDescription(
		serviceCodes: string[] = [],
		requirement: IRequirement
	) {
		const codes = serviceCodes.sort()
		const codeDescriptions: string[] = []

		if (codes.length) {
			const placesOfService = store.getters['worklist/getPlacesOfService']

			// Find and build the description for each place of service code
			codes.forEach((code: string) => {
				const placeOfService = placesOfService.find(
					(place: IPlaceOfService) => place.code === code
				)

				if (placeOfService) {
					const description = `${placeOfService.code} - ${placeOfService.description}`
					codeDescriptions.push(description)
				}
			})

			const deliminatedCodes =
				this.deliminateDescriptionValues(codeDescriptions)
			requirement.statement += `Place of service is ${deliminatedCodes}. `
		}
	}

	private getDiagnosisDescription(
		diagnosisParameters: IDiagnosisParameters,
		requirement: IRequirement
	) {
		if (diagnosisParameters.categories || diagnosisParameters.codes) {
			const categories = diagnosisParameters.categories.map(
				category => category.name
			)
			const diagnosisCodes = [...categories, ...diagnosisParameters.codes]
			const codes = diagnosisCodes.sort()

			if (codes.length) {
				const deliminatedCodes = this.deliminateDescriptionValues(codes)
				requirement.statement += `Diagnosis code is ${deliminatedCodes}. `
			}
		}
	}

	private getPriceDescription(
		procedurePriceParameters: IProcedureWithOperationParameters,
		requirement: IRequirement
	) {
		const operation = procedurePriceParameters.operation
		let low = procedurePriceParameters.low
		let high = procedurePriceParameters.high
		let price = `${low || high}`

		const validOperation =
			(low && operation !== FieldOperation.BETWEEN) ||
			(low && high && operation === FieldOperation.BETWEEN)

		if (validOperation) {
			const { toCurrency } = useFormatting()

			// Format price values for display
			price = toCurrency(price || 0)
			low = toCurrency(low || 0)
			high = toCurrency(high || 0)

			switch (operation) {
				case FieldOperation.EQ:
					price = `${price}`
					break
				case FieldOperation.GT:
					price = `> ${price}`
					break
				case FieldOperation.GTE:
					price = `>= ${price}`
					break
				case FieldOperation.LT:
					price = `< ${price}`
					break
				case FieldOperation.LTE:
					price = `<= ${price}`
					break
				case FieldOperation.BETWEEN:
					price = `between ${low} and ${high}`
					break
			}

			requirement.statement += `Price is ${price}. `
		}
	}

	private getQuantityDescription(
		quantityParameters: IProcedureWithOperationParameters,
		requirement: IRequirement
	) {
		const operation = quantityParameters.operation
		const low = quantityParameters.low
		const high = quantityParameters.high
		let quantity = `${low || high}`

		const validOperation =
			(low && operation !== FieldOperation.BETWEEN) ||
			(low && high && operation === FieldOperation.BETWEEN)

		if (validOperation) {
			switch (operation) {
				case FieldOperation.EQ:
					quantity = `${quantity}`
					break
				case FieldOperation.GT:
					quantity = `> ${quantity}`
					break
				case FieldOperation.GTE:
					quantity = `>= ${quantity}`
					break
				case FieldOperation.LT:
					quantity = `< ${quantity}`
					break
				case FieldOperation.LTE:
					quantity = `<= ${quantity}`
					break
				case FieldOperation.BETWEEN:
					quantity = `between ${low} and ${high}`
					break
			}

			requirement.statement += `Quantity is ${quantity}. `
		}
	}

	private getProcedureCodeMofifierDescription(
		codeModifiers: string[] = [],
		requirement: IRequirement
	) {
		if (codeModifiers.length) {
			const deliminatedModifiers =
				this.deliminateDescriptionValues(codeModifiers)
			requirement.statement += `Modifier is ${deliminatedModifiers}. `
		}
	}

	private getPlanNameDescription(
		planNames: any[] = [],
		requirement: IRequirement
	) {
		const ids = planNames.map(plan => plan.id)

		if (ids.length) {
			const plans = store.getters['worklist/getPlans']

			let selectedPlans = plans.filter((plan: any) => ids.includes(plan.id))
			selectedPlans = selectedPlans.map((plan: any) => plan.name).sort()

			const deliminatedNames = this.deliminateDescriptionValues(selectedPlans)
			requirement.statement += `Plan name is ${deliminatedNames}. `
		}
	}

	private deliminateDescriptionValues(values: string[] = []) {
		let deliminatedValues = ''

		if (values.length === 1) {
			deliminatedValues = values[0]
		} else if (values.length === 2) {
			deliminatedValues = values.join(' and ')
		} else {
			const lastValue = values.pop()
			deliminatedValues = values.join(', ') + ', and ' + lastValue
		}

		return deliminatedValues
	}
}
