
import {
	defineComponent,
	SetupContext,
	ref,
	watch,
	computed,
	PropType,
	watchEffect,
} from 'vue'
import RequirementItem from '@/components/worklist/requirements/requirement-item.vue'
import DefaultRequirement from '@/components/worklist/requirements/default-requirement.vue'
import {
	fetchRequirementSet,
	createRequirementSet,
	updateRequirementSet,
} from '@/service/requirements.http'
import store from '@/store'
import { Alert, toast } from '@/dependencies/sweet-alert'
import { IWorklistItem } from '@server/api/models/fe/worklist'
import {
	IRequirement,
	IRequirementOutcome,
	IRequirementSet,
} from '@server/api/models/fe/requirements'
import {
	CopyPasteType,
	FieldType,
	RequirementType,
} from '@server/api/models/fe/shared'
import {
	fetchForms,
	fetchSubmissionConfigurations,
} from '@/service/worklist.http'
import { IForm, ISubmissionConfiguration } from '@/models/worklists/outcome'
import { RequirementStatement } from './requirement-statement.class'

export default defineComponent({
	name: 'requirement-set',
	components: {
		[RequirementItem.name]: RequirementItem,
		[DefaultRequirement.name]: DefaultRequirement,
	},
	props: {
		requirementSetId: String,
		worklistItem: { type: Object as PropType<IWorklistItem>, required: true },
		canEditDetail: {
			default: false,
			type: Boolean,
		},
	},
	setup(props, context: SetupContext) {
		const formOptions = ref<IForm[]>([])
		const submissionConfigurationOptions = ref<ISubmissionConfiguration[]>([])
		const requirementSet = ref<IRequirementSet | null>(null)
		const matchingRequirement = ref<IRequirement>()
		const isUpdating = ref(false)
		const isLoading = ref(false)
		const noPermissionsMessage = `You don't have permission`
		const formIds = computed<string[]>(
			() =>
				requirementSet.value?.requirements?.map(
					(req: any) => req?.outcome?.authSubmissionFormId || ''
				) || []
		)

		// TODO - check for the submission config id too
		const submissionConfigurationIds = computed<string[]>(
			() =>
				requirementSet.value?.requirements?.map(
					(req: any) => req?.outcome?.authSubmissionPortalId || ''
				) || []
		)

		watchEffect(() => {
			if (props.requirementSetId) {
				isLoading.value = true
				fetchRequirementSet(props.requirementSetId).then(
					(set: IRequirementSet) => {
						storeDefaultRequirement(set)

						requirementSet.value = set
						if (!set.requirements.length) {
							// Add default starting requirement
							const newRequirement: IRequirement = {
								statement: 'New Requirement Set',
								fields: [],
							}
							requirementSet.value.requirements = [newRequirement]
							updateSet()
						}
						isLoading.value = false
					}
				)
			} else {
				requirementSet.value = null
			}
		})

		const createNewRequirementSet = () => {
			createRequirementSet().then(id =>
				context.emit('associateRequirements', id)
			)
		}

		const deleteRequirementSet = () => {
			context.emit('deleteRequirementSet', props.worklistItem)
		}

		const deleteRequirement = (requirement: IRequirement) => {
			if (requirementSet.value) {
				const index = requirementSet.value.requirements.findIndex(
					item => item.id === requirement.id
				)
				requirementSet.value.requirements.splice(index, 1)

				const callback = () => {
					if (
						requirementSet.value &&
						requirementSet.value.requirements.length === 0
					) {
						deleteRequirementSet()
					}
				}
				updateSet(callback)
			}
		}

		const moveUp = (index: number) => {
			if (requirementSet.value && index !== 0) {
				isUpdating.value = true
				const temp = requirementSet.value.requirements[index]
				requirementSet.value.requirements[index] =
					requirementSet.value.requirements[index - 1]
				requirementSet.value.requirements[index - 1] = temp
				requirementSet.value.requirements = [
					...requirementSet.value.requirements,
				]
				updateSet()
			}
		}

		const moveDown = (index: number) => {
			if (
				requirementSet.value &&
				index < requirementSet.value.requirements.length - 1
			) {
				isUpdating.value = true
				const temp = requirementSet.value.requirements[index]
				requirementSet.value.requirements[index] =
					requirementSet.value.requirements[index + 1]
				requirementSet.value.requirements[index + 1] = temp
				requirementSet.value.requirements = [
					...requirementSet.value.requirements,
				]
				updateSet()
			}
		}

		const copyRequirementSet = () => {
			if (requirementSet.value?.hasDefaultRequirement) {
				requirementSet.value.requirements.push({
					statement:
						'Prior authorization defaults to the root record if no requirements are matched',
					fields: [
						{
							type: FieldType.MATCH_EVERYTHING,
						},
					],
				})
			}
			store.commit('copyPaste/setRequirementSet', {
				requirementSet: requirementSet.value,
				payerId: props.worklistItem.payer.id,
				planId: props.worklistItem.plan.id,
			})
			toast({ type: Alert.SUCCESS, title: 'Requirement Set Copied' })
		}

		const canPaste = computed(() => {
			return !!store.state.copyPaste.type
		})

		const hasMatchingPayerPlan = computed(() => {
			const payerId = props.worklistItem.payer.id
			const planId = props.worklistItem.plan.id
			const copyPayerId = store.state.copyPaste.payerId
			const copyPlanId = store.state.copyPaste.planId
			return copyPayerId === payerId && copyPlanId === planId
		})

		const isPasteRequirementSet = computed(() => {
			return store.state.copyPaste.type === CopyPasteType.REQUIREMENT_SET
		})

		const isPasteRequirement = computed(() => {
			return store.state.copyPaste.type === CopyPasteType.REQUIREMENT
		})

		const pasteSetTooltip = computed(() => {
			if (!props.canEditDetail) {
				return `You don't have permission`
			}
			if (!canPaste.value) {
				return `Nothing on clipboard`
			}
			if (!hasMatchingPayerPlan.value) {
				return `Can't Paste: Payer or Plan of copied value do not match`
			}
			if (!isPasteRequirementSet.value) {
				return `Can't Paste: Clipboard value not compatible`
			}
			return 'Paste Requirement Set'
		})

		const pasteRequirementSet = () => {
			if (hasMatchingPayerPlan.value && isPasteRequirementSet.value) {
				const set = store.state.copyPaste.requirementSet
				isLoading.value = true
				if (!requirementSet.value) {
					createRequirementSet().then(id => {
						requirementSet.value = {
							id,
							type: RequirementType.BASIC,
							requirements: set.requirements,
							hasDefaultRequirement: set.hasDefaultRequirement,
						}
						updateSet(() => {
							// loading will be set back to false when reqs are associated w/ wl item
							context.emit('associateRequirements', id)
							toast({ type: Alert.SUCCESS, title: 'Requirement Set Pasted' })
						})
					})
				} else {
					requirementSet.value = {
						...requirementSet.value,
						requirements: [
							...requirementSet.value.requirements,
							...set.requirements,
						],
					}
					updateSet(() => {
						toast({ type: Alert.SUCCESS, title: 'Requirement Set Pasted' })
					})
					isLoading.value = false
				}
			}
		}

		const updateSet = (callback?: () => void) => {
			if (requirementSet.value) {
				isUpdating.value = true

				let updatedRequirementSet = JSON.parse(
					JSON.stringify(requirementSet.value)
				)

				// Matching-requirement must be the last requirement
				if (matchingRequirement.value) {
					updatedRequirementSet.requirements.push(matchingRequirement.value)
				}

				updatedRequirementSet = new RequirementStatement(
					updatedRequirementSet
				).updateRequirementStatements()

				updateRequirementSet(updatedRequirementSet).then(value => {
					storeDefaultRequirement(value)
					requirementSet.value = value
					isUpdating.value = false

					context.emit('checkRequirement', props.worklistItem)
					if (callback) {
						callback()
					}
				})
			}
		}

		const storeDefaultRequirement = (set: IRequirementSet): void => {
			// Remove matching-requirement from set
			if (set && set.hasDefaultRequirement) {
				const index = set.requirements.findIndex(item => {
					return item.fields
						? item.fields.find(
								field => field.type === FieldType.MATCH_EVERYTHING
						  )
						: -1
				})

				if (index > -1) {
					matchingRequirement.value = set.requirements.splice(index, 1)[0]
				}
			}
		}

		const onSetDefaultRequirement = () => {
			if (requirementSet.value) {
				if (requirementSet.value.hasDefaultRequirement) {
					matchingRequirement.value = undefined
				} else {
					matchingRequirement.value = {
						statement:
							'Prior authorization defaults to the root record if no requirements are matched',
						fields: [
							{
								type: FieldType.MATCH_EVERYTHING,
							},
						],
					}
				}
				updateSet()
			}
		}

		const addRequirement = () => {
			const newRequirement: IRequirement = {
				statement: 'Add requirement description',
				fields: [],
				outcome: {} as IRequirementOutcome,
			}
			if (requirementSet.value) {
				requirementSet.value.requirements.push(newRequirement)
				requirementSet.value.requirements = [
					...(requirementSet.value.requirements ?? []),
				]
			}
			updateSet()
		}

		const pasteRequirement = (index: number) => {
			if (hasMatchingPayerPlan.value && isPasteRequirement.value) {
				const pastedRequirement: IRequirement = {
					statement: store.state.copyPaste.requirement.statement,
					fields: store.state.copyPaste.requirement.fields,
					outcome: store.state.copyPaste.requirement.outcome,
				}
				if (requirementSet.value) {
					requirementSet.value.requirements.splice(
						index + 1,
						0,
						pastedRequirement
					)
					requirementSet.value.requirements = [
						...(requirementSet.value.requirements ?? []),
					]
				}
				updateSet(() => {
					toast({ type: Alert.SUCCESS, title: 'Requirement Set Pasted' })
				})
			}
		}

		const deleteRequirementSetTooltip = computed(() => {
			return !props.canEditDetail
				? `You don't have permission`
				: 'Delete Requirement Set'
		})

		const createRequirementSetTooltip = computed(() => {
			return !props.canEditDetail
				? `You don't have permission`
				: 'Create Requirement Set'
		})

		const addRequirementTooltip = computed(() => {
			return !props.canEditDetail ? noPermissionsMessage : 'Add Requirement'
		})

		watch(formIds, ids => {
			const validIds = ids.filter((id?: string | null) => !!id)
			fetchForms(null, validIds).then(res => {
				formOptions.value = res
			})
		})

		watch(submissionConfigurationIds, ids => {
			const validIds = ids.filter((id?: string | null) => !!id)
			fetchSubmissionConfigurations(null, validIds).then(res => {
				submissionConfigurationOptions.value = res
			})
		})

		return {
			requirementSet,
			isUpdating,
			isLoading,
			hasMatchingPayerPlan,
			isPasteRequirement,
			isPasteRequirementSet,
			canPaste,
			pasteSetTooltip,
			deleteRequirementSetTooltip,
			addRequirementTooltip,
			createRequirementSetTooltip,
			formOptions,
			submissionConfigurationOptions,

			moveUp,
			moveDown,
			createNewRequirementSet,
			deleteRequirement,
			deleteRequirementSet,
			addRequirement,
			updateSet,
			copyRequirementSet,
			pasteRequirementSet,
			pasteRequirement,
			onSetDefaultRequirement,
		}
	},
})
