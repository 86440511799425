
import {
	defineComponent,
	onMounted,
	ref,
	SetupContext,
	watch,
	PropType,
} from 'vue'
import FuzzySearch from 'fuzzy-search'
import { fetchDiagnosisCodes } from '@/service/worklist.http'
import { IDiagnosisParameters } from '@server/api/models/fe/requirements'
import {
	IDiagnosisCategoryDisplay,
	IDiagnosisSectionDisplay,
} from '@server/api/models/fe/shared'

export default defineComponent({
	name: 'diagnosis-parameters',
	components: {},
	props: {
		parameters: {
			type: Object as PropType<IDiagnosisParameters>,
			required: true,
		},
	},
	setup(props, context: SetupContext) {
		const codesList = ref<IDiagnosisSectionDisplay[]>([])
		const options = ref<IDiagnosisSectionDisplay[]>([])
		const selectedCategories = ref(props.parameters.categories ?? [])
		const selectedCodes = ref(props.parameters.codes ?? [])
		const codeErrors = ref<string[]>([])

		onMounted(() => {
			fetchDiagnosisCodes().then((res: any) => {
				codesList.value = res
				options.value = codesList.value
			})
		})

		watch(selectedCategories, (_, prev) => {
			if (prev) {
				props.parameters.categories = selectedCategories.value
				context.emit('updateField')
			}
		})

		watch(selectedCodes, (_, prev) => {
			if (prev) {
				props.parameters.codes = selectedCodes.value
				context.emit('updateField')
			}
		})

		const filterCategories = (query: string) => {
			const searcher = new FuzzySearch(
				codesList.value,
				['description', 'categories.name', 'categories.description'],
				{
					caseSensitive: false,
				}
			)
			options.value = searcher.search(query)
		}

		const addCode = (newTag: string) => {
			const regex = /^[A-Z][0-9][0-9ABab](\.?[0-9A-TV-Za-tv-z]{1,4})?$/
			codeErrors.value = []
			if (!regex.test(newTag)) {
				codeErrors.value.push('*Invalid code')
			}
			if (!codeErrors.value.length) {
				selectedCodes.value.push(newTag)
			}
		}

		const removeCategory = (category: IDiagnosisCategoryDisplay) => {
			const index = selectedCategories.value
				.map(c => c.name)
				.indexOf(category.name)
			selectedCategories.value.splice(index, 1)
		}

		return {
			options,
			selectedCategories,
			selectedCodes,
			codeErrors,

			filterCategories,
			addCode,
			removeCategory,
		}
	},
})
