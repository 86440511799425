import axios from 'axios'
import { IRequirementSet } from '@server/api/models/fe/requirements'
import interceptor from './interceptor'
import { getBff } from './shared.http'

axios.interceptors.request.use(interceptor as any)

export async function fetchRequirementSet(
	id: string
): Promise<IRequirementSet> {
	const path = `${getBff()}/requirements/${id}/draft`
	const response = await axios.get<IRequirementSet>(path)
	return response.data
}

export async function fetchPaRequirementSet(
	id: string
): Promise<IRequirementSet> {
	const path = `${getBff()}/requirements/${id}`
	const response = await axios.get<IRequirementSet>(path)
	return response.data
}

export async function createRequirementSet(): Promise<string> {
	const path = `${getBff()}/requirements`
	const response = await axios.post(path, {})
	return response.data
}

export async function updateRequirementSet(
	requirementSet: IRequirementSet
): Promise<IRequirementSet> {
	const path = `${getBff()}/requirements/${requirementSet.id}/draft`
	const response = await axios.put<IRequirementSet>(path, requirementSet)
	return response.data
}
