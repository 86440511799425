
import {
	defineComponent,
	SetupContext,
	onMounted,
	ref,
	watch,
	PropType,
} from 'vue'
import { MyndshftPanel } from '@myndshft/panel'
import { fetchProcedureCategories } from '@/service/worklist.http'
import {
	IProcedureCategory,
	IWorklistBasicPayer,
	IWorklistBasicPlan,
	IWorklistFilterCode,
	IWorklistFilterData,
	WorklistItemStatus,
	WorklistItemStatusName,
} from '@server/api/models/fe/worklist'

export default defineComponent({
	name: 'worklist-filter',
	components: {
		[MyndshftPanel.name]: MyndshftPanel,
	},
	props: {
		filterData: {
			type: Object as PropType<IWorklistFilterData>,
			required: true,
		},
	},
	setup(props, context: SetupContext) {
		const payerList = ref<IWorklistBasicPayer[]>(props.filterData.payers)
		const plansList = ref<IWorklistBasicPlan[]>(props.filterData.plans)
		const categoriesList = ref<IProcedureCategory[]>([])
		const statusesList = ref<WorklistItemStatus[]>(
			props.filterData?.statuses || []
		)
		const codeList = ref<IWorklistFilterCode[]>(props.filterData.codes)
		const worklistItemStatusName = WorklistItemStatusName
		const selectedPayerId = ref(null)
		const selectedPlanId = ref(null)
		const selectedCategory = ref(null)
		const selectedStatus = ref<WorklistItemStatus | null>(null)
		const selectedCode = ref(null)

		onMounted(() => {
			setCategories()
		})

		watch(
			() => props.filterData,
			({ payers, plans, statuses, codes }) => {
				payerList.value = payers
				plansList.value = plans
				statusesList.value = statuses ?? []
				codeList.value = codes
			}
		)

		function setCategories() {
			fetchProcedureCategories().then(categoryData => {
				categoriesList.value = categoryData.categories.sort(
					(cat1: IProcedureCategory, cat2: IProcedureCategory) => {
						return cat1.name < cat2.name ? -1 : 1
					}
				)
			})
		}

		function onFilterSelect() {
			context.emit('onFilterChange', {
				payerId: selectedPayerId.value,
				planId: selectedPlanId.value,
				procedureCategory: selectedCategory.value,
				status: selectedStatus.value,
				code: selectedCode.value,
			})
		}

		return {
			payerList,
			plansList,
			categoriesList,
			statusesList,
			selectedPayerId,
			selectedPlanId,
			selectedCategory,
			selectedStatus,
			worklistItemStatusName,
			codeList,
			selectedCode,

			onFilterSelect,
		}
	},
})
