
import {
	defineComponent,
	SetupContext,
	ref,
	computed,
	watch,
	PropType,
	watchEffect,
} from 'vue'
import { fetchAssets } from '@/service/worklist.http'
import { AssetType } from '@myndshft/types-mc-api'
import { IGuideline } from '@server/api/models/fe/shared'
import {
	IWorklistItem,
	WorklistItemStatus,
} from '@server/api/models/fe/worklist'
import { ITableProp } from '@server/api/models/fe/shared'

export default defineComponent({
	name: 'worklist-guideline',
	props: {
		item: {
			type: Object as PropType<IWorklistItem & ITableProp>,
			required: true,
		},
		canEdit: {
			type: Boolean,
			default: false,
		},
		isBulkEditing: Boolean,
		haveSamePayer: Boolean,
		index: Number,
	},
	setup(props, context: SetupContext) {
		const selectedGuidelines = ref<IGuideline[]>([])
		const guidelines = ref<IGuideline[]>([])
		const show = ref(false)
		const updatedGuideline = ref<IGuideline>()
		const isAdded = ref(false)

		const canSelect = computed(() => {
			return (
				props.canEdit &&
				// while in bulk edit state, only selected items can be edited
				(props.isBulkEditing
					? props.item.isSelected && props.haveSamePayer
					: true) &&
				props.item.status !== WorklistItemStatus.PENDING_PUBLISH
			)
		})
		const disabledMessage = computed<string | null>(() => {
			if (props.isBulkEditing) {
				if (!props.item.isSelected) {
					return 'Item is not selected for bulk editing'
				} else if (!props.haveSamePayer) {
					return 'The selected items do not share the same Payer'
				}
			}
			return null
		})

		const isLoading = ref(false)

		const fetchItems = () => {
			isLoading.value = true
			fetchAssets(props.item.payer.id, {
				type: AssetType.GUIDELINE,
			}).then((payerGuidelines: IGuideline[]) => {
				guidelines.value = payerGuidelines
				isLoading.value = false
			})
		}

		const storeSelected = (arr1: IGuideline[], arr2: IGuideline[]) => {
			const sortedArrays = [arr1, arr2].sort((a, b) => a.length - b.length)
			const set = new Set(sortedArrays[0])

			return sortedArrays[1].filter((item: IGuideline) => !set.has(item))[0]
		}

		const isSelected = (guideline: IGuideline): boolean => {
			return selectedGuidelines.value.findIndex(g => g.id === guideline.id) > -1
		}

		const onInput = (guidelineItems: IGuideline[]) => {
			updatedGuideline.value = storeSelected(
				props.item.outcome.assets,
				guidelineItems
			)
			isAdded.value = props.item.outcome.assets.length < guidelineItems.length

			context.emit('onItemUpdate', updateOperation)
		}

		const updateOperation = (): {
			itemCopy: IWorklistItem
			values: { value: IGuideline | undefined; isAdded: boolean }
		} => {
			const itemCopy = JSON.parse(JSON.stringify(props.item))
			itemCopy.outcome.assets = selectedGuidelines.value
			itemCopy.isSaving.guideline = true

			// Provide value to be added or removed for bulk update operation
			const values = { value: updatedGuideline.value, isAdded: isAdded.value }

			return { itemCopy, values }
		}

		watchEffect(() => {
			selectedGuidelines.value = props.item.outcome.assets
		})

		return {
			selectedGuidelines,
			guidelines,
			show,
			isLoading,
			canSelect,
			disabledMessage,

			onInput,
			fetchItems,
			isSelected,
		}
	},
})
