
import {
	defineComponent,
	SetupContext,
	reactive,
	ref,
	computed,
	PropType,
	watchEffect,
} from 'vue'
import { fetchItemFaxes } from '@/service/worklist.http'
import { IResponseFaxMethod } from '@myndshft/types-mc-api'
import {
	IWorklistItem,
	WorklistItemStatus,
} from '@server/api/models/fe/worklist'
import { ITableProp } from '@server/api/models/fe/shared'

interface IContactFax extends IResponseFaxMethod {
	selected?: boolean
}

export default defineComponent({
	name: 'worklist-fax',
	props: {
		item: {
			type: Object as PropType<IWorklistItem & ITableProp>,
			required: true,
		},
		canEdit: {
			type: Boolean,
			default: false,
		},
		isBulkEditing: Boolean,
		haveSamePayerPlan: Boolean,
		index: Number,
	},
	setup(props, context: SetupContext) {
		const selectedContacts = ref<IContactFax[]>([])
		const faxes = reactive({
			list: [],
		})
		const show = ref(false)
		const isLoading = ref(false)
		const updatedFax = ref<IContactFax>()
		const isAdded = ref(false)
		const isPreferred = ref(false)

		const canSelect = computed(() => {
			return (
				props.canEdit &&
				// while in bulk edit state, only selected items can be edited
				(props.isBulkEditing
					? props.item.isSelected && props.haveSamePayerPlan
					: true) &&
				props.item.status !== WorklistItemStatus.PENDING_PUBLISH
			)
		})
		const disabledMessage = computed<string | null>(() => {
			if (props.isBulkEditing) {
				if (!props.item.isSelected) {
					return 'Item is not selected for bulk editing'
				} else if (!props.haveSamePayerPlan) {
					return 'The selected items do not share the same Payer/Plan'
				}
			}
			return null
		})

		const fetchItems = () => {
			isLoading.value = true
			// Get fax selections if it does not exist
			fetchItemFaxes(props.item.payer.id, props.item.plan.id).then(response => {
				faxes.list = response
				isLoading.value = false
			})
		}

		const isSelected = (faxOption: IContactFax) => {
			return (
				selectedContacts.value.findIndex(fax => fax.id === faxOption.id) > -1
			)
		}

		const storeSelected = (arr1: IContactFax[], arr2: IContactFax[]) => {
			const sortedArrays = [arr1, arr2].sort((a, b) => a.length - b.length)
			const set = new Set(sortedArrays[0])

			return sortedArrays[1].filter((item: IContactFax) => !set.has(item))[0]
		}

		const onInput = (faxItems: IContactFax[]) => {
			if (props.item.outcome.contactMethods.faxes) {
				updatedFax.value = storeSelected(
					props.item.outcome.contactMethods.faxes,
					faxItems
				)
			}
			if (props.item.outcome.contactMethods.faxes) {
				isAdded.value =
					props.item.outcome.contactMethods.faxes.length < faxItems.length
			}
			context.emit('onItemUpdate', updateOperation)
		}

		const updateOperation = (): {
			itemCopy: IWorklistItem
			values: { value: IContactFax | undefined; isAdded: boolean }
		} => {
			const itemCopy = JSON.parse(JSON.stringify(props.item))
			itemCopy.outcome.contactMethods.faxes = selectedContacts.value
			itemCopy.isSaving.fax = true

			// Provide value to be added or removed for bulk update operation
			const values = { value: updatedFax.value, isAdded: isAdded.value }

			return { itemCopy, values }
		}

		const onPreferredToggle = () => {
			if (canSelect.value) {
				context.emit('onItemUpdate', updateOperationPreferredMethod)
			}
		}

		const updateOperationPreferredMethod = (): { itemCopy: IWorklistItem } => {
			const itemCopy = JSON.parse(JSON.stringify(props.item))
			itemCopy.outcome.preferredMethod = isPreferred.value ? null : 'FAX'
			itemCopy.isSaving.preferredMethod = true
			return { itemCopy }
		}

		watchEffect(() => {
			const itemFaxes = props.item.outcome.contactMethods.faxes
			if (itemFaxes) {
				selectedContacts.value = itemFaxes
			}
		})

		watchEffect(() => {
			isPreferred.value = props.item.outcome.preferredMethod === 'FAX'
		})

		return {
			selectedContacts,
			faxes,
			show,
			isLoading,
			isPreferred,
			canSelect,
			disabledMessage,

			onInput,
			fetchItems,
			isSelected,
			onPreferredToggle,
		}
	},
})
