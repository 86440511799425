
import { defineComponent, ref, computed, watch, onMounted } from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'

import {
	IWorklistConflicts,
	IWorklistConflictsItem,
} from '@server/api/models/fe/worklist'
import { fetchWorklistConflicts } from '@/service/worklist.http'

export default defineComponent({
	name: 'show-conflicts-button',
	props: {
		worklistId: { type: String, required: true },
	},
	setup(props) {
		const defaultSetting = {
			prevPageToken: [null],
			nextPageToken: [],
			pageIndex: 0,
			conflicts: [],
		}
		const isLoadingConflicts = ref(false)
		const showConflictTable = ref(false)
		const prevPageToken = ref<Array<string | null>>(
			defaultSetting.prevPageToken
		)
		const nextPageToken = ref<Array<string | null>>(
			defaultSetting.nextPageToken
		)
		const pageIndex = ref(defaultSetting.pageIndex)
		const conflicts = ref<Array<IWorklistConflictsItem>>(
			defaultSetting.conflicts
		)
		const isDisabled = computed(() => !conflicts.value.length)

		const headers = [
			{
				text: 'Payer',
				value: 'payer',
			},
			{
				text: 'Plan',
				value: 'plan',
			},
			{
				text: 'Code',
				value: 'procedure',
			},
			{
				text: 'Reason',
				value: 'reason',
			},
		]

		const disablePrev = computed(() => {
			return !prevPageToken.value[pageIndex.value]
		})
		const disableNext = computed(() => {
			return !nextPageToken.value[pageIndex.value]
		})

		const handlePageChange = (isNext: boolean) => {
			const pageToken = isNext
				? nextPageToken.value[pageIndex.value]
				: prevPageToken.value[pageIndex.value - 1]
			isLoadingConflicts.value = true
			fetchWorklistConflicts(props.worklistId, pageToken)
				.then((response: IWorklistConflicts) => {
					conflicts.value = response.conflicts
					if (isNext && pageIndex.value + 1 >= nextPageToken.value.length) {
						prevPageToken.value.push(nextPageToken.value[pageIndex.value])
						nextPageToken.value.push(response.nextPageToken || null)
						pageIndex.value++
					} else if (isNext) {
						pageIndex.value++
					} else {
						pageIndex.value--
					}
				})
				.catch(error => {
					const message =
						error?.response?.data?.message ||
						'Failed to get worklist conflicts: Unknown error occurred'
					toast({
						type: Alert.ERROR,
						title: message,
						timer: 5000,
					})
				})
				.finally(() => {
					isLoadingConflicts.value = false
				})
		}

		watch(
			() => props.worklistId,
			(newId, prevId) => {
				if (newId !== prevId) {
					isLoadingConflicts.value = true
					fetchWorklistConflicts(props.worklistId)
						.then((response: IWorklistConflicts) => {
							nextPageToken.value.push(response.nextPageToken || null)
							conflicts.value = response.conflicts
						})
						.catch(error => {
							const message =
								error?.response?.data?.message ||
								'Failed to get worklist conflicts: Unknown error occurred'
							toast({
								type: Alert.ERROR,
								title: message,
								timer: 5000,
							})
						})
						.finally(() => {
							isLoadingConflicts.value = false
						})
				}
			}
		)

		onMounted(() => {
			isLoadingConflicts.value = true
			fetchWorklistConflicts(props.worklistId)
				.then((response: IWorklistConflicts) => {
					nextPageToken.value.push(response.nextPageToken || null)
					conflicts.value = response.conflicts
				})
				.catch(error => {
					const message =
						error?.response?.data?.message ||
						'Failed to get worklist conflicts: Unknown error occurred'
					toast({
						type: Alert.ERROR,
						title: message,
						timer: 5000,
					})
				})
				.finally(() => {
					isLoadingConflicts.value = false
				})
		})

		return {
			isLoadingConflicts,
			showConflictTable,
			headers,
			conflicts,
			disablePrev,
			disableNext,
			isDisabled,
			handlePageChange,
		}
	},
})
