
import { defineComponent } from 'vue'

import Worklist from '@/components/worklist/worklist.vue'

export default defineComponent({
	components: {
		Worklist,
	},
	setup() {
		return {}
	},
})
