
import {
	computed,
	defineComponent,
	ref,
	SetupContext,
	watch,
	PropType,
} from 'vue'
import RequirementField from '@/components/worklist/requirements/requirement-field.vue'
import ContactMethods from '@/components/worklist/requirements/outcome/contact-methods.vue'
import { v4 as uuidv4 } from 'uuid'
import store from '@/store'
import { Alert, toast } from '@/dependencies/sweet-alert'
import {
	IRequirement,
	IRequirementField,
} from '@server/api/models/fe/requirements'
import { IWorklistItem } from '@server/api/models/fe/worklist'
import {
	AuthRequirement,
	AuthRequirementName,
} from '@server/api/models/fe/shared'
import { IForm, ISubmissionConfiguration } from '@/models/worklists/outcome'
import {
	fetchForms,
	fetchSubmissionConfigurations,
} from '@/service/worklist.http'
import { RequirementStatementFormatter } from './requirement-statement-formatter.class'

export default defineComponent({
	name: 'requirement-item',
	components: {
		[RequirementField.name]: RequirementField,
		[ContactMethods.name]: ContactMethods,
	},
	props: {
		requirement: { type: Object as PropType<IRequirement>, required: true },
		worklistItem: { type: Object as PropType<IWorklistItem>, required: true },
		isFirst: Boolean,
		isLast: Boolean,
		canEditDetail: {
			default: false,
			type: Boolean,
		},
		hasMatchingPayerPlan: Boolean,
		isPasteRequirement: Boolean,
		canPaste: Boolean,
		initialFormOptions: Array as PropType<IForm[]>,
		initialSubmissionConfigurationOptions: Array as PropType<
			ISubmissionConfiguration[]
		>,
	},
	setup(props, context: SetupContext) {
		const statement = ref(props.requirement.statement)
		const statementLabel = computed(() => {
			return statement.value
				? new RequirementStatementFormatter(
						props.requirement.statement
				  ).formatStatements()
				: 'Add requirement description'
		})

		const fields = ref<IRequirementField[]>([...props.requirement.fields] ?? [])
		const isShowingFields = ref(false)
		const expandable = ref<HTMLElement | null>(null)
		const paOptions = Object.values(AuthRequirement)
		const authRequirementName = AuthRequirementName
		const selectedAuthRequirement = ref(
			props.requirement.outcome?.authRequirement
		)
		const selectedFax = ref<IForm | null>(null)
		const selectedSubmissionConfiguration =
			ref<ISubmissionConfiguration | null>(null)
		const loadingFormOptions = ref<number | null>(null)
		const loadingSubmissionConfigurationOptions = ref<number | null>(null)
		const formOptions = ref<IForm[]>([])
		const submissionConfigurationOptions = ref<ISubmissionConfiguration[]>([])

		const canAddField = computed(() => {
			// Disable adding new fields until newly added field is given a type and saved
			return !fields.value.some(field => !field.type)
		})

		const pasteRequirementTooltip = computed(() => {
			if (!props.canPaste) {
				return `Nothing on clipboard`
			}
			if (!props.hasMatchingPayerPlan) {
				return `Can't Paste: Payer or Plan of copied value do not match`
			}
			if (!props.isPasteRequirement) {
				return `Can't Paste: Clipboard value not compatible`
			}
			return 'Paste Requirement'
		})

		const moveUp = () => {
			context.emit('moveUp')
		}

		const moveDown = () => {
			context.emit('moveDown')
		}

		const pasteRequirement = () => {
			context.emit('pasteRequirement')
		}

		const expandFields = () => {
			isShowingFields.value = !isShowingFields.value
		}

		const addField = () => {
			if (canAddField.value) {
				fields.value.push({
					id: uuidv4(),
					parameters: {},
				} as IRequirementField)
				fields.value = [...fields.value]

				if (!isShowingFields.value) {
					expandFields()
				}
			}
		}

		const updateFields = () => {
			props.requirement.fields = fields.value

			context.emit('updateRequirement')
		}

		const deleteField = (index: number) => {
			fields.value.splice(index, 1)
			updateFields()
		}

		const updateRequirement = () => {
			context.emit('updateRequirement')
		}

		const onPaSelect = () => {
			if (props.requirement.outcome) {
				props.requirement.outcome.authRequirement =
					selectedAuthRequirement.value
				context.emit('updateRequirement')
			}
		}

		const onFaxSelect = () => {
			if (props.requirement.outcome) {
				props.requirement.outcome.authSubmissionFormId = selectedFax.value?.id
				props.requirement.outcome.authSubmissionPortalId = null
				selectedSubmissionConfiguration.value = null

				if (selectedFax.value) {
					// Store selected form in worklist store for use in next selection
					store.dispatch('worklist/setForm', selectedFax.value as IForm)
				}

				context.emit('updateRequirement')
			}
		}

		const onSubmissionConfigSelect = () => {
			if (props.requirement.outcome) {
				props.requirement.outcome.submissionConfigurationId =
					selectedSubmissionConfiguration.value?.id
				props.requirement.outcome.authSubmissionFormId = null
				selectedFax.value = null

				if (selectedSubmissionConfiguration.value) {
					store.dispatch(
						'worklist/setSubmission',
						selectedSubmissionConfiguration.value as ISubmissionConfiguration
					)
				}

				context.emit('updateRequirement')
			}
		}

		const onFaxOpen = () => {
			fetchForms(null, null).then(options => {
				formOptions.value = options

				setSelectedForm()
			})
		}

		const onSubmissionConfigurationOpen = () => {
			fetchSubmissionConfigurations(null, null).then(options => {
				submissionConfigurationOptions.value = options
				setSelectedSubmissionConfiguration()
			})
		}

		const loadFaxOptions = async (search: string | null, id: string | null) => {
			formOptions.value = ((await fetchForms(search, id ? [id] : null)) ||
				[]) as IForm[]
			if (loadingFormOptions.value) {
				clearTimeout(loadingFormOptions.value)
				loadingFormOptions.value = null
			}
		}

		const loadSubmissionConfigurationOptions = async (
			search: string | null,
			id: string | null
		) => {
			submissionConfigurationOptions.value =
				((await fetchSubmissionConfigurations(search, id ? [id] : null)) ||
					[]) as ISubmissionConfiguration[]
			if (loadingSubmissionConfigurationOptions.value) {
				clearTimeout(loadingSubmissionConfigurationOptions.value)
				loadingSubmissionConfigurationOptions.value = null
			}
		}

		const onFaxSearch = (search?: string | null) => {
			if (loadingFormOptions.value) {
				clearTimeout(loadingFormOptions.value)
			}
			if (search) {
				loadingFormOptions.value = setTimeout(
					() => loadFaxOptions(search || null, null),
					500
				)
			}
		}

		const onSubmissionConfigurationSearch = (search?: string | null) => {
			if (loadingSubmissionConfigurationOptions.value) {
				clearTimeout(loadingSubmissionConfigurationOptions.value)
			}
			if (search) {
				loadingSubmissionConfigurationOptions.value = setTimeout(
					() => loadSubmissionConfigurationOptions(search || null, null),
					500
				)
			}
		}

		const deleteRequirement = () => {
			context.emit('deleteRequirement', props.requirement)
		}

		const copyRequirement = () => {
			store.commit('copyPaste/setRequirement', {
				requirement: {
					statement: statement.value,
					fields: fields.value,
					outcome: props.requirement.outcome,
				},
				payerId: props.worklistItem.payer.id,
				planId: props.worklistItem.plan.id,
			})
			toast({ type: Alert.SUCCESS, title: 'Requirement Copied' })
		}

		const noPermissionsMessage = `You don't have permission`

		const addFieldTooltip = computed(() => {
			if (!props.canEditDetail) {
				return noPermissionsMessage
			}
			return canAddField
				? 'Add Field'
				: 'Select Type for New Field before adding more'
		})

		const editStatementTooltip = computed(() => {
			return !props.canEditDetail ? noPermissionsMessage : 'Edit Statement'
		})

		const deleteRequirementTooltip = computed(() => {
			return !props.canEditDetail ? noPermissionsMessage : 'Delete Requirement'
		})

		const expandHandleTooltip = computed(() => {
			if (!fields.value.length) {
				return 'No Requirement Fields'
			} else if (isShowingFields.value) {
				return 'Collapse Requirement Fields'
			} else {
				return 'Show Requirement Fields'
			}
		})

		const setSelectedForm = () => {
			if (
				formOptions.value.length > 0 &&
				props.requirement?.outcome?.authSubmissionFormId
			) {
				selectedFax.value =
					formOptions.value.find(
						form => form.id === props.requirement?.outcome?.authSubmissionFormId
					) || null
			}
		}

		const setSelectedSubmissionConfiguration = () => {
			if (
				submissionConfigurationOptions.value.length > 0 &&
				props.requirement?.outcome?.authSubmissionPortalId
			) {
				selectedSubmissionConfiguration.value =
					submissionConfigurationOptions.value.find(
						submissionConfig =>
							submissionConfig.id ===
							props.requirement?.outcome?.authSubmissionPortalId
					) || null
			}
		}

		watch(
			() => props.requirement.fields,
			propFields => {
				fields.value = [...propFields]
			}
		)

		watch(
			() => props.initialFormOptions,
			options => {
				if (options && options.length > 0) {
					formOptions.value = options as IForm[]
				}

				setSelectedForm()
			}
		)

		watch(
			() => props.initialSubmissionConfigurationOptions,
			options => {
				if (options && options.length > 0) {
					submissionConfigurationOptions.value =
						options as ISubmissionConfiguration[]
				}

				setSelectedSubmissionConfiguration()
			}
		)

		return {
			statement,
			statementLabel,
			editStatementTooltip,
			fields,
			isShowingFields,
			expandable,
			expandHandleTooltip,
			canAddField,
			addFieldTooltip,
			paOptions,
			formOptions,
			submissionConfigurationOptions,
			loadingFormOptions,
			loadingSubmissionConfigurationOptions,
			authRequirementName,
			selectedAuthRequirement,
			pasteRequirementTooltip,
			selectedFax,
			selectedSubmissionConfiguration,
			deleteRequirementTooltip,

			moveUp,
			moveDown,
			deleteRequirement,
			expandFields,
			addField,
			updateFields,
			deleteField,
			updateRequirement,
			onPaSelect,
			onFaxSelect,
			onFaxSearch,
			onFaxOpen,
			onSubmissionConfigurationSearch,
			onSubmissionConfigurationOpen,
			onSubmissionConfigSelect,
			copyRequirement,
			pasteRequirement,
		}
	},
})
