import { IItemOutcome } from '@/models/worklists/outcome'

export enum ActiveStatus {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}

export enum WorklistItemStatus {
	IN_PROGRESS = 'IN_PROGRESS',
	IN_REVIEW = 'IN_REVIEW',
	PENDING_PUBLISH = 'PENDING_PUBLISH',
	FAILED_PUBLISH = 'FAILED_PUBLISH',
}

export interface IWorklistItemSummary {
	meetsRequirements: boolean
}

export interface IWorklistItemPlan {
	id: string
	type: string
}

export interface IWorklistItemPayer {
	id: string
	name: string
}

export interface IWorklistItem {
	active: ActiveStatus
	id: string
	payer: IWorklistItemPayer
	plan: IWorklistItemPlan
	procedureCode: string
	procedureCategory: string
	outcome: IItemOutcome
	status: WorklistItemStatus
	requirementSet: {
		id: string
	} | null
	summary: IWorklistItemSummary
	meta: {
		lastModified: string
		lastModifiedBy: string
	}
}
