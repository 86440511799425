
import { defineComponent, ref, SetupContext } from 'vue'
import {
	WorklistItemStatus,
	WorklistItemStatusName,
} from '@server/api/models/fe/worklist'

export default defineComponent({
	name: 'status-selection',
	props: {
		canEditDetail: {
			type: Boolean,
			default: false,
		},
		canEditProgress: {
			type: Boolean,
			default: false,
		},
		haveSameStatus: {
			type: Boolean,
			default: true,
		},
	},
	setup(props, context: SetupContext) {
		const isActive = ref(false)
		const status = WorklistItemStatus
		const statusName = WorklistItemStatusName

		const onSelected = (selectedStatus: WorklistItemStatus) => {
			if (
				(selectedStatus === WorklistItemStatus.IN_PROGRESS &&
					props.canEditProgress &&
					props.haveSameStatus) ||
				(selectedStatus === WorklistItemStatus.IN_REVIEW &&
					props.canEditDetail &&
					props.haveSameStatus)
			) {
				isActive.value = false
				context.emit('statusUpdate', selectedStatus)
			}
		}

		const activateDropdown = () => {
			if (props.haveSameStatus) {
				isActive.value = !isActive.value
			}
		}

		return {
			isActive,
			onSelected,
			status,
			statusName,
			activateDropdown,
		}
	},
})
