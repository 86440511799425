export class RequirementStatementFormatter {
	private statements: string[] = []
	private statementPrefixes: string[] = [
		'State is',
		'Modifier is',
		'Place of service is',
		'Diagnosis code is',
		'Procedure is',
		'Plan name is',
		'Price is',
		'Quantity is',
	]

	constructor(statement = '') {
		this.statements = statement.split('. ')
	}

	formatStatements() {
		let modifiedStatement = ''
		this.statements.forEach((statement: string) => {
			const prefix = this.statementPrefixes.find(statementPrefix =>
				statement.startsWith(statementPrefix)
			)
			modifiedStatement += prefix
				? this.modifyStatement(statement, prefix)
				: statement
		})

		return modifiedStatement
	}

	private modifyStatement(statement: string, statementPrefix: string) {
		const regex = new RegExp(`${statementPrefix} ((?:[^,]+,?s*)+)`)
		const replacement = '<strong>$1</strong>'
		const modifiedStatement = statement.replace(
			regex,
			`${statementPrefix} ${replacement}. `
		)

		return modifiedStatement
	}
}
