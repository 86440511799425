import { render, staticRenderFns } from "./paste-requirements-button.vue?vue&type=template&id=b4d68734&scoped=true"
import script from "./paste-requirements-button.vue?vue&type=script&lang=ts"
export * from "./paste-requirements-button.vue?vue&type=script&lang=ts"
import style0 from "./paste-requirements-button.vue?vue&type=style&index=0&id=b4d68734&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4d68734",
  null
  
)

export default component.exports