
import {
	defineComponent,
	ref,
	SetupContext,
	onBeforeMount,
	PropType,
} from 'vue'
import { IPlaceOfServiceParameters } from '@server/api/models/fe/requirements'
import { IPlaceOfService } from '@server/api/models/fe/shared'
import { fetchPlacesOfService } from '@/service/shared.http'

export default defineComponent({
	name: 'pos-parameters',
	components: {},
	props: {
		parameters: {
			type: Object as PropType<IPlaceOfServiceParameters>,
			required: true,
		},
		canEditDetail: Boolean,
	},
	setup(props, context: SetupContext) {
		const options = ref<IPlaceOfService[]>([])
		const selectedCodes = ref(props.parameters.codes ?? [])

		const updateParameters = () => {
			props.parameters.codes = selectedCodes.value
			context.emit('updateField')
		}

		onBeforeMount(async () => {
			options.value = await fetchPlacesOfService()
		})

		return {
			options,
			selectedCodes,

			updateParameters,
		}
	},
})
