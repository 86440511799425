
import { defineComponent, ref, SetupContext, PropType } from 'vue'
import { modifiers } from '@/components/worklist/requirements/parameters/modifier-options'
import { IProcedureCodeParameters } from '@server/api/models/fe/requirements'

export default defineComponent({
	name: 'code-parameters',
	components: {},
	props: {
		parameters: {
			type: Object as PropType<IProcedureCodeParameters>,
			required: true,
		},
		canEditDetail: Boolean,
	},
	setup(props, context: SetupContext) {
		const options = modifiers.sort()
		const selectedModifiers = ref(props.parameters.modifiers ?? [])

		const updateParameters = () => {
			props.parameters.modifiers = selectedModifiers.value
			context.emit('updateField')
		}

		return {
			options,
			selectedModifiers,

			updateParameters,
		}
	},
})
