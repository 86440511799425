
import { defineComponent, ref, SetupContext } from 'vue'

export default defineComponent({
	name: 'code-description-button',
	setup(_, context: SetupContext) {
		const showCodeDescriptions = ref(false)

		const onShowCodeDescriptions = () => {
			showCodeDescriptions.value = !showCodeDescriptions.value

			context.emit('showCodeDescriptions', showCodeDescriptions.value)
		}

		return {
			showCodeDescriptions,

			onShowCodeDescriptions,
		}
	},
})
