
import { defineComponent, ref, SetupContext, PropType } from 'vue'
import { states } from '@/components/shared/configuration/states'
import { IPatientStateParameters } from '@server/api/models/fe/requirements'

export default defineComponent({
	name: 'state-parameters',
	components: {},
	props: {
		parameters: {
			type: Object as PropType<IPatientStateParameters>,
			required: true,
		},
		canEditDetail: Boolean,
	},
	setup(props, context: SetupContext) {
		// prettier-ignore
		const options = states.sort((a,b)=> {
			return a.code < b.code ? -1 : 1
		})
		const selectedStates = ref(props.parameters.state ?? [])

		const updateParameters = () => {
			props.parameters.state = selectedStates.value
			context.emit('updateField')
		}

		return {
			options,
			selectedStates,

			updateParameters,
		}
	},
})
