
import { computed, defineComponent, ref, SetupContext, PropType } from 'vue'
import MsInput from '@/components/form/input.vue'
import { useFormatting } from '@/dependencies/formatting'
import { IProcedureWithOperationParameters } from '@server/api/models/fe/requirements'
import { FieldOperation } from '@server/api/models/fe/shared'

export default defineComponent({
	name: 'price-parameters',
	components: {
		MsInput,
	},
	props: {
		parameters: {
			type: Object as PropType<IProcedureWithOperationParameters>,
			required: true,
		},
		isEditingOperation: Boolean,
		canEditDetail: Boolean,
	},
	setup(props, context: SetupContext) {
		const low = ref(props.parameters.low)
		const high = ref(props.parameters.high)
		const isBetween = computed(() => {
			return props.parameters.operation === FieldOperation.BETWEEN
		})
		const isEditingPrices = ref(!low.value)
		const { toCurrency } = useFormatting()

		const updateParameters = () => {
			props.parameters.low = low.value
			props.parameters.high = high.value
			context.emit('updateField')
			isEditingPrices.value = false
		}

		return {
			isBetween,
			isEditingPrices,
			low,
			high,

			updateParameters,
			toCurrency,
		}
	},
})
