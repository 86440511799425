
import { defineComponent, ref, computed, onBeforeUnmount, watch } from 'vue'
import { useStore } from '@/utils/composables'

import {
	fetchForms,
	fetchSubmissionConfigurations,
} from '@/service/worklist.http'
import { WorklistItemStatus, IWorklistItem } from '@/models/worklists/item'
import {
	IConfigurationOption,
	IForm,
	ISubmissionConfiguration,
} from '@/models/worklists/outcome'

enum ConfigurationType {
	FAX = 'FAX',
	PORTAL = 'PORTAL',
	SUBMISSION = 'SUBMISSION',
}

export default defineComponent({
	name: 'worklist-pa-configuration-selection',
	props: {
		canEdit: {
			type: Boolean,
			default: false,
		},
		configurationType: {
			type: String,
			default: () => ConfigurationType.FAX,
		},
		item: Object,
		itemConfigurationId: String,
		isBulkEditing: Boolean,
		isSaving: Boolean,
		index: Number,
		initialOptions: {
			type: Array,
			default: () => [],
		},
	},
	setup(props, context) {
		const store = useStore()
		const selectedConfiguration = ref<IConfigurationOption | null>(null)
		const options = ref<IConfigurationOption[]>([])
		const show = ref(false)
		const loadingOptions = ref<any>(null)

		const canSelect = computed(() => {
			return (
				props.canEdit &&
				(props.isBulkEditing ? props.item?.isSelected : true) &&
				props.item?.status !== WorklistItemStatus.PENDING_PUBLISH
			)
		})

		const disabledMessage = computed<string | null>(() => {
			if (props.isBulkEditing) {
				if (!props.item?.isSelected) {
					return 'Item is not selected for bulk editing'
				}
			}
			return null
		})

		const fetchConfigurationOptions = async (
			search: string | null,
			id: string | null,
			type: ConfigurationType
		) => {
			switch (type) {
				case ConfigurationType.FAX:
					options.value = ((await fetchForms(search, id ? [id] : null)) ||
						[]) as IConfigurationOption[]
					break
				case ConfigurationType.SUBMISSION || ConfigurationType.PORTAL:
					options.value = ((await fetchSubmissionConfigurations(
						search,
						id ? [id] : null
					)) || []) as IConfigurationOption[]
					break
				default:
					options.value = []
			}

			if (loadingOptions.value) {
				clearTimeout(loadingOptions.value)
				loadingOptions.value = null
			}
		}

		const onSearch = (search?: string | null) => {
			if (loadingOptions.value) {
				clearTimeout(loadingOptions.value)
			}
			if (props.configurationType === ConfigurationType.FAX) {
				loadingOptions.value = setTimeout(
					() =>
						fetchConfigurationOptions(
							search || null,
							props?.itemConfigurationId || null,
							ConfigurationType.FAX
						),
					500
				)
			} else if (
				props.configurationType === ConfigurationType.SUBMISSION ||
				props.configurationType === ConfigurationType.PORTAL
			) {
				loadingOptions.value = setTimeout(
					() =>
						fetchConfigurationOptions(
							search || null,
							props?.itemConfigurationId || null,
							ConfigurationType.SUBMISSION
						),
					500
				)
			}
		}

		const onInput = () => {
			context.emit('onItemUpdate', updateOperation)
		}

		const updateOperation = (): { itemCopy: IWorklistItem } => {
			const itemCopy = JSON.parse(JSON.stringify(props.item))
			switch (props.configurationType) {
				case ConfigurationType.FAX:
					itemCopy.outcome.authSubmissionFormId =
						selectedConfiguration.value?.id
					itemCopy.isSaving.authSubmissionFormId = true
					itemCopy.outcome.authSubmissionPortalId = null
					itemCopy.isSaving.authSubmissionPortalId = false
					break
				case ConfigurationType.SUBMISSION:
					itemCopy.outcome.submissionConfigurationId =
						selectedConfiguration.value?.id
					itemCopy.isSaving.submissionConfigurationId = true
					itemCopy.outcome.authSubmissionFormId = null
					itemCopy.isSaving.authSubmissionFormId = false
					break
				case ConfigurationType.PORTAL:
					itemCopy.outcome.authSubmissionPortalId =
						selectedConfiguration.value?.id
					itemCopy.isSaving.authSubmissionPortalId = true
					itemCopy.outcome.authSubmissionFormId = null
					itemCopy.isSaving.authSubmissionFormId = false
					break
				default:
					itemCopy.outcome.authSubmissionFormId = null
					itemCopy.isSaving.authSubmissionFormId = false
					itemCopy.outcome.authSubmissionPortalId = null
					itemCopy.isSaving.authSubmissionPortalId = false
					itemCopy.outcome.authSubmissionConfigurationId = null
					itemCopy.isSaving.authSubmissionPortalId = false
			}

			if (selectedConfiguration.value) {
				switch (props?.configurationType) {
					case ConfigurationType.FAX:
						// Store selected form in worklist store for use in next selection
						store.dispatch(
							'worklist/setForm',
							selectedConfiguration.value as IForm
						)
						break
					case ConfigurationType.SUBMISSION:
						store.dispatch(
							'worklist/setSubmission',
							selectedConfiguration.value as ISubmissionConfiguration
						)
						break
					case ConfigurationType.PORTAL:
						store.dispatch(
							'worklist/setSubmission',
							selectedConfiguration.value as ISubmissionConfiguration
						)
						break
					default:
						throw Error('Invalid configuration type')
				}
			}

			return { itemCopy }
		}

		const isSelected = (configuration: IConfigurationOption) => {
			return selectedConfiguration.value?.id === configuration.id
		}

		const getNameById = (id: string): string => {
			return options.value?.find(opt => opt.id === id)?.name || id
		}

		const getPlaceholderById = (
			id: string,
			type: ConfigurationType
		): string => {
			const name = options.value?.find(opt => opt.id === id)?.name
			let defaultText = ''
			switch (type) {
				case ConfigurationType.FAX:
					defaultText = 'Fax'
					break
				case ConfigurationType.SUBMISSION || ConfigurationType.PORTAL:
					defaultText = 'Submission'
					break
				default:
			}
			return name || defaultText
		}

		watch(
			() => props.initialOptions,
			(opts: any) => {
				options.value = opts as IConfigurationOption[]
				if (props.itemConfigurationId) {
					const option: IConfigurationOption | null = opts?.find(
						(opt: any) => opt.id === props.itemConfigurationId
					)
					selectedConfiguration.value = option || null
				}
			}
		)

		watch(
			() => props.itemConfigurationId,
			id => {
				if (!id) {
					selectedConfiguration.value = null
				}
			}
		)

		onBeforeUnmount(() => {
			if (loadingOptions.value) {
				clearTimeout(loadingOptions.value)
			}
		})

		return {
			show,
			canSelect,
			disabledMessage,
			options,
			selectedConfiguration,
			loadingOptions,

			isSelected,
			onInput,
			onSearch,
			getNameById,
			getPlaceholderById,
		}
	},
})
