
import { defineComponent, SetupContext } from 'vue'

export default defineComponent({
	name: 'delete-requirements-button',
	props: {
		isDeletingRequirements: {
			type: Boolean,
			default: false,
		},
		isBulkEditing: {
			type: Boolean,
			default: false,
		},
		canDeleteRequirements: {
			type: Boolean,
			default: false,
		},
	},
	setup(_, context: SetupContext) {
		const onBulkDeleteRequirements = () => {
			context.emit('deleteRequirements')
		}
		return {
			onBulkDeleteRequirements,
		}
	},
})
